/// <reference types='@types/googlemaps' />
import {
  Component, OnDestroy, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft, faSave, faDumpster, faInfoCircle, faHandPaper, faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '@veritas-shared/services/toast.service';
import { DumpsterService } from '../../services/dumpster.service';
import { ViewMode } from '@veritas-shared/models/view-mode';
import { ViewModeHelper } from '@veritas-shared/helpers/view-mode-helper';
import { TranslateService } from '@ngx-translate/core';
import { DumpsterListDeactivateDumpstersInput } from '../../models/dumpster-list-deactivate-dumpsters-input';
import { SessionService } from '@veritas-shared/services/session.service';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { DumpsterListAssociateCollectionPointInput } from '../../models/dumpster-list-associate-collectionpoint-input';
import { CollectionPointListOutputCollectionPoint } from '@veritas-shared/models/collectionpoint-list-output';
import { DumpsterListDeassociateCollectionPointInput } from '../../models/dumpster-list-deassociate-collectionpoint-input';
import { ModalService } from '@veritas-shared/services/modal.service';
import { DumpsterListActivateDumpstersInput } from '../../models/dumpster-list-activate-dumpsters-input';
import { GridColumn } from '@veritas-shared/models/grid-column';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { Observable, of, Subscription, combineLatest } from 'rxjs';
import { Group } from '../../../group/models/group';
import { Action } from '@veritas-shared/models/action';
import {
  BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective,
} from 'bean-kendo-dialog';
import { CollectionPointSelectDialogComponent, CollectionPointSelectDialogInput } from '@veritas-shared/components/collection-point-select-dialog/collection-point-select-dialog.component';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DumpsterEventsOutput, DumpsterEventsOutputItem } from '../../models/dumpster-events';
import { 
  DumpsterEventDetailDialogComponent, DumpsterEventDetailDialogInput,
} from '../../components/dumpster-event-detail-dialog/dumpster-event-detail-dialog.component';
import { EntityListOutput } from '@veritas-shared/models/entities/entity-list-output';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { SecurityService } from '@veritas-shared/services/security.service';
import { DumpsterEventDetailInput } from '../../models/dumpster-event-detail';
import { DumpsterStatusOutput } from '../../models/dumpster-status';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NavigationService } from '@veritas-shared/services/navigation.service';
import {
  DumpsterConfigurationComponent, DumpsterConfigurationDialogInput,
} from '@veritas-dumpsters/components/dumpster-configuration-dialog/dumpster-configuration-dialog.component';
import { TicketService } from 'src/app/tickets/services/ticket.service';
import { TicketBodyOutput, TicketListRequestInput } from 'src/app/tickets/models/ticket-list-output';
import { coordinationType } from '@veritas-shared/enums/leaflet-map.enum';
import { EditDumpsterOutput } from '@veritas-dumpsters/models/edit-dumpster-output';
import { ServiceConst } from '@veritas-dumpsters/enums/dumpsters.enums';
import { IRelationIcon, relationIcon } from '@veritas-dumpsters/constants/dumpster-relation.const';
import { NewDumpsterOutputDumpster } from '@veritas-dumpsters/models/new-dumpster-output';
import { DumpsterGeneralForm } from '@veritas-dumpsters/classes/dumpster-general-form.class';
import { DumpsterLocationService } from '@veritas-dumpsters/services/dumpster-location.service';
import { MapIconService } from '@veritas-dumpsters/services/map-icon.service';
import { EventTranslateService } from '../../services/event-translate.service';

@Component({
  selector: 'app-dumpster-detail',
  templateUrl: './dumpster-detail.component.html',
  styleUrls: ['./dumpster-detail.component.scss'],
})
export class DumpsterDetailComponent extends DumpsterGeneralForm implements OnInit, OnDestroy {
  @ViewChild('columnPayloadTypeTemplate', { static: true })
  columnPayloadTypeTemplate: TemplateRef<any>;
  @ViewChild(WindowVisualizerDirective, { static: true })
  public windowVisualizer: WindowVisualizerDirective;

  public hasWritePermission: boolean;
  public groups: Group[] = [];
  public deleteAction: Action;
  public toolbarActions: Action[] = [];
  public ViewMode = ViewMode;
  private dumpsterId: number;
  public dumpster: Dumpster;
  public dumpsterStatusData: DumpsterStatusOutput;
  public events: DumpsterEventsOutputItem[];
  public eventsColumns: GridColumn[] = [];
  public eventsDefaultSort: SortDescriptor;
  public selectedDumpsters: Dumpster[] = [];
  public ticket: TicketBodyOutput;
  public collectionPoints: CollectionPointListOutputCollectionPoint[] = [];
  public entities: EntityListOutput;
  public hasTicket: boolean = false;
  public ticketChecked: boolean = false;
  public addressTypeIcon: string;
  public addressTooltip: string;

  public readonly relation: IRelationIcon = relationIcon;
  public baseUrl = window.location.origin;
  public coordinationType = coordinationType;

  // Subscription
  private querySubscription: Subscription;

  constructor(
    private ticketService: TicketService,
    private toastService: ToastService,
    private translate: TranslateService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private library: FaIconLibrary,
    public constantHelper: ConstantHelper,
    private commonHelper: CommonHelper,
    private dialogService: BeanKendoDialogService,
    private securityService: SecurityService,
    private iconService: MapIconService,
    private eventTranslateService: EventTranslateService,
    protected dumpsterService: DumpsterService,
    protected navigationService: NavigationService,
    protected fb: FormBuilder,
    protected locationService: DumpsterLocationService,
  ) // private configurationService: DumpsterConfigurationComponent
  {
    super(fb, navigationService, dumpsterService, locationService);

    this.addressTooltip = coordinationType.googleMaps;

    this.onEdit = this.onEdit.bind(this);
    this.onView = this.onView.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.delete = this.delete.bind(this);
    this.newTicket = this.newTicket.bind(this);
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.associate = this.associate.bind(this);
    this.deassociate = this.deassociate.bind(this);
    this.openEventDetailDialog = this.openEventDetailDialog.bind(this);

    this.library.addIcons(faSave, faChevronLeft, faDumpster, faInfoCircle, faHandPaper, faLocationArrow);

    // Params
    this.route.params.subscribe((params) => {
      if (params) {
        this.dumpsterId = +params.dumpsterId;
      }
    });

    // Query Params
    this.querySubscription = combineLatest(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      ),
      this.route.queryParams
    ).subscribe((res) => {
      // Set ViewMode
      this.viewMode = ViewModeHelper.getViewMode(this.route, 'dumpsterId');

      // Enalbe / Disable the form
      if (this.formDumpster) {
        let newForm = this.getFormGroup();
        this.commonHelper.enableValidFormFields(this.formDumpster, newForm);
      }

      // Set PageTitle
      if (this.viewMode == ViewMode.View && this.dumpster) {
        this.sessionService.setPageTitle(this.dumpster.code);
      }
    });

    this.securityService
    .userHasPermission(this.constantHelper.LBL_PERMISSION_DUMPSTER_WRITE)
    .subscribe((res) => {
      this.hasWritePermission = res;

      if (res) {
        this.toolbarActions = [
          new Action(
            'delete',
            this.constantHelper.LBL_ACTION_COMMON_DELETE,
            'trash-alt',
            this.delete,
            null,
            false,
            () => this.dumpster && this.dumpster.deactivated
          ),
          new Action(
            'deactivate',
            this.constantHelper.LBL_ACTION_COMMON_DEACTIVATE,
            'power-off',
            this.deactivate,
            null,
            false,
            () => this.dumpster && !this.dumpster.deactivated
          ),
          new Action(
            'reactivate',
            this.constantHelper.LBL_ACTION_DUMPSTER_REACTIVATE,
            'power-off',
            this.activate,
            null,
            false,
            () => this.dumpster && this.dumpster.deactivated
          ),
          new Action(
            'remove',
            this.constantHelper.LBL_ACTION_COLLPOINT_DELETE_DUMPSTER,
            'crosshairs',
            this.deassociate,
            null,
            false,
            () => this.dumpster && this.dumpster.collectionPoint
          ),
          new Action(
            'configuration',
            this.constantHelper.LBL_ACTION_DUMPSTER_SET_CONFIGURATION,
            'bars',
            this.dumpsterConfiguration,
            null,
            false
          )
        ];
      }
    });
  }

  ngOnInit() {
    this.formDumpster = this.getFormGroup();

    switch (this.viewMode) {
      case ViewMode.Edit:
      case ViewMode.View:
        this.reload();
        break;
      case ViewMode.New:
        this.dumpsterService.getNewDumpster().subscribe(
          (result) => this._setFormGroup<NewDumpsterOutputDumpster>(result?.dumpster, result?.dumpster?.location)
        );
        break;
    }

    this.eventsDefaultSort = {
      field: 'timestamp',
      dir: 'desc',
    };

    this.eventsColumns = [
      {
        field: 'payloadType',
        title: 'Tipo',
        sortable: true,
        resizable: true,
        filterable: true,
        display: 'link',
        callback: this.openEventDetailDialog,
        template: this.columnPayloadTypeTemplate,
      },
      {
        field: 'timestamp',
        title: 'Data',
        sortable: true,
        resizable: true,
        filterable: true,
        filter: 'date',
        display: 'date',
        dateType: this.constantHelper.DATE_TIME_FORMAT,
      },
      {
        field: 'messageType',
        title: 'Messaggio',
        sortable: true,
        resizable: true,
        filterable: false,
        display: 'text',
      },
    ];

    // Load
    this.loadAllCollectionPoints();
    this._setLocationUpdate();
  }

  ngOnDestroy() {
    if (this.querySubscription != null) {
      this.querySubscription.unsubscribe();
    }
  }

  private getTicket(metadataFilter: any){
    const ticketRequest: TicketListRequestInput = {
      state: ['Opened'],
      assignedTo: null,
      assignedFrom: null,
      entityType: null,
      currentDeviceId: null,
      currentBinTag: null,
      startDate: null,
      endDate: null,
      openingReasons: [],
      closureReasons: [],
      metadataFilter: metadataFilter,
      subject: null,
      tag: null,
      ticketOwnerEmail: null,
      orderByCreationDateDescending: true,
      pageSize: 1,
      pageNumber: 0,
    };

    var ticket = this.ticketService.getTickets(ticketRequest).subscribe((x) => {
      this.ticket = x.tickets[0];
      this.hasTicket = this.ticket != null;
      this.ticketChecked = true;
    });

    return ticket
  }

  public onEdit = () => this.router.navigate([], {
    queryParams: { edit: true },
    relativeTo: this.route,
  });
  public onView = () => this.router.navigate([], { relativeTo: this.route });
  public onBack = () => this.goBack();
  public onCancel = () => this.formDumpster.reset(this.dumpster);
  public refresh(): void {
    window.location.reload();
    return;
  }

  //todo
  public dumpsterConfiguration = () => {
    this.dialogService.openDialog(
      this._getModalTitle(this.dumpster.code),
      new DumpsterConfigurationDialogInput(this.dumpster),
      DumpsterConfigurationComponent,
      this.windowVisualizer,
      this.refresh,
      null,
      true,
      WindowContainerDimension.Medium,
      { mediumHeight: 450, mediumWidth: 1300 } as BeanDialogDimension
    );

    // this.dialogService.openDialog(
    //   this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTERDETAIL_DIALOG_COLLECTION_POINT_TITLE),
    //   new CollectionPointSelectDialogInput(this.allCollectionPoints, 'single'),
    //   CollectionPointSelectDialogComponent,
    //   this.windowVisualizer,
    //   () => { },
    //   (res: CollectionPointListOutputCollectionPoint[]) => {

    //     const associatedCollectionPointId = res.map(d => d.id);
    //     const input = new DumpsterListAssociateCollectionPointInput([this.dumpsterId], associatedCollectionPointId[0]);

    //     return this.dumpsterService.associateCollectionPoint(input).pipe(map((result) => {
    //       this.toastService.showSuccess(
    //         this.translate.instant(this.constantHelper.LBL_PAGE_DEASSOCIATEDDUMPSTERS_DIALOG_ASSOCIATE_COLLPOINT_CONFIRM_MESSAGE, { '0': associatedCollectionPointId.length })
    //       );
    //       this.reload();
    //     }));
    //   },
    //   true,
    //   WindowContainerDimension.Medium,
    //   {
    //     mediumHeight: 600,
    //     mediumWidth: 1000,
    //   } as BeanDialogDimension
    // );
  }

  public activate() {
    const toActivate = new DumpsterListActivateDumpstersInput([
      this.dumpsterId,
    ]);
    this.dumpsterService.activateDumpsters(toActivate).subscribe((result) => {
      this.toastService.showSuccess(
        this.translate.instant(
          this.constantHelper
            .LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE,
          { '0': this.selectedDumpsters.length }
        )
      );
      this.reload();
    });
  }

  public deactivate() {
    const toDeactivate = new DumpsterListDeactivateDumpstersInput([
      this.dumpsterId,
    ]);
    this.dumpsterService
      .deactivateDumpsters(toDeactivate)
      .subscribe((result) => {
        this.toastService.showSuccess(
          this.translate.instant(
            this.constantHelper
              .LBL_PAGE_DEVICEDETAIL_DIALOG_DEACTIVATE_CONFIRM_MESSAGE,
            { '0': 1 }
          )
        );
        this.reload();
      });
  }

  public delete() {
    this.modalService.showConfirm(
      this.translate.instant(this.constantHelper.LBL_COMMON_CONFIRM_DELETE),
      () => {
        this.dumpsterService
          .deleteDumpster(this.dumpsterId)
          .subscribe((result) => {
            this.toastService.showSuccess(
              this.translate.instant(
                this.constantHelper
                  .LBL_PAGE_DEVICEDETAIL_DIALOG_DEACTIVATE_CONFIRM_MESSAGE,
                { '0': 1 }
              )
            );
            this.goBack();
          });
      }
    );
  }

  public deassociate() {
    let toDeassociate = new DumpsterListDeassociateCollectionPointInput([
      this.dumpsterId,
    ]);
    this.dumpsterService
      .deassociateCollectionPoint(toDeassociate)
      .subscribe((result) => {
        this.toastService.showSuccess(
          this.translate.instant(
            this.constantHelper
              .LBL_PAGE_DEVICEDETAIL_DIALOG_DEACTIVATE_CONFIRM_MESSAGE,
            { '0': 1 }
          )
        );
        this.reload();
      });
  }

  public reload() {
    this.dumpsterService.getEditDumpster(this.dumpsterId).subscribe({
      next: (result: EditDumpsterOutput) => {
        this.dumpster = result.dumpster;
        this.dumpsters = [result.dumpster];

        const metadataFilter = {}

        if(this.dumpster.binId){
          metadataFilter['OpeningBinTag'] = this.dumpster.binId;
        }
        else if(this.dumpster.code){
          metadataFilter['OpeningDeviceId'] = this.dumpster.code;
        }

        if(metadataFilter['OpeningDeviceId'] || metadataFilter['OpeningBinTag']){
          of(this.dumpster).subscribe(res => this.getTicket(metadataFilter));
        }

        this.sessionService.setPageTitle(result.dumpster.code)

        this._setFormGroup<Dumpster>(result?.dumpster, result?.dumpster?.location);
        if (result.dumpster.collectionPoint) {
          this.formDumpster
            .get('collectionPointId')
            .patchValue(result.dumpster.collectionPoint.id);
          this.formDumpster
            .get('collectionPointName')
            .patchValue(result.dumpster.collectionPoint.name);
        } else {
          this.formDumpster.get('collectionPointId').patchValue(null);
          this.formDumpster.get('collectionPointName').patchValue(null);
        }

        this.entities = result.entities;

        this.groups = result.groups;
        this.dumpsterStatusData = result.status;

        this.events = this.eventTranslateService.getConfiguredEvents(result.events);

        const address = this.formDumpster.get('address');

        //
        if (this.dumpsterStatusData) {
          this.formDumpster.get('imei').setValue(this.dumpsterStatusData.imei);

          if (this.dumpsterStatusData.supplierCustomInfo) {
            this.formDumpster
              .get('tag')
              .setValue(this.dumpsterStatusData.supplierCustomInfo.dumpsterTag);
          }

          if (this.dumpsterStatusData.fillLevelPercentage == -1) {
            this.dumpsterStatusData.fillLevelPercentage = null;
          }
        }

        if (this.dumpster.customAddress
          && this.dumpster.customLatitude
          && this.dumpster.customLongitude) {
          address.setValue(this.dumpster.customAddress);

          this._setLocation({
            latitude: this.dumpster.location.latitude,
            longitude: this.dumpster.location.longitude
          });

          this.addressTypeIcon = 'hand-paper';
          this.addressTooltip = coordinationType.manually;
        }
        else {
          address.setValue(this.dumpster.address);

          this._setLocation({
            latitude: this.dumpster.location.latitude,
            longitude: this.dumpster.location.longitude
          });

          this.addressTypeIcon = 'location-arrow';
        }
      },
      complete: () => this._getEventByDumpsterId(),
    });
  }

  private _getModalTitle = (
    code: string
  ) => `${this.translate.instant(this.constantHelper.LBL_PAGE_DEVICEDETAIL_DIALOG_CONFIG_TITLE)} - ${code}`;

  private _getDumpsterEventsTimeRange = () => {
    const dumpsterEvents = this.events.map(event => event.timestamp).sort();
    return {
      firstEventTimestamp: this.events.length > 0 ? dumpsterEvents[0] : null,
      lastEventTimestamp: this.events.length > 0 ? dumpsterEvents[this.events.length - 1] : null,
    }
  }
 
  // this call will be made to Blacklist events
  private _getEventByDumpsterId = () => {
    const { firstEventTimestamp, lastEventTimestamp } = this._getDumpsterEventsTimeRange();
 
    this.dumpsterService.getDumpsterEvents({
      service: ServiceConst.CommonService,
      supplier: 'Veritas',
      entityId: `${this.dumpster?.deviceExternalId}`,
      fromDate: firstEventTimestamp,
      toDate: lastEventTimestamp
    }).subscribe({
        next: (events: DumpsterEventsOutput) => {
          this.events = [
            ...this.events,
            ...this.eventTranslateService.getConfiguredEvents(events.events),
          ].sort((event, event2) => (event.timestamp > event2.timestamp ? -1 : 1))  // => OrderByDescending
        }
      })
  }

  public associate() {
    let observable: Observable<any>;

    if (this.allCollectionPoints.length == 0) {
      observable = this.loadAllCollectionPoints();
    } else {
      observable = of(this.allCollectionPoints);
    }

    observable.subscribe((res) => {
      this.dialogService.openDialog(
        this.translate.instant(
          this.constantHelper
            .LBL_PAGE_DUMPSTERDETAIL_DIALOG_COLLECTION_POINT_TITLE
        ),
        new CollectionPointSelectDialogInput(
          this.allCollectionPoints,
          'single'
        ),
        CollectionPointSelectDialogComponent,
        this.windowVisualizer,
        () => {},
        (res: CollectionPointListOutputCollectionPoint[]) => {
          const associatedCollectionPointId = res.map((d) => d.id);
          const input = new DumpsterListAssociateCollectionPointInput(
            [this.dumpsterId],
            associatedCollectionPointId[0]
          );

          return this.dumpsterService.associateCollectionPoint(input).pipe(
            map((result) => {
              this.toastService.showSuccess(
                this.translate.instant(
                  this.constantHelper
                    .LBL_PAGE_DEASSOCIATEDDUMPSTERS_DIALOG_ASSOCIATE_COLLPOINT_CONFIRM_MESSAGE,
                  { '0': associatedCollectionPointId.length }
                )
              );
              this.reload();
            })
          );
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  public handleCollectionPointsFilter(value) {
    this.collectionPoints = this.allCollectionPoints.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onCollectionPointSelected(collectionPointId: number) {
    if (collectionPointId === undefined) {
      this._setLocationDisable(false);
      return;
    }

    const collPoint = this.allCollectionPoints.find(
      (cp) => cp.id == collectionPointId
    );
    if (collPoint.address) {
      this._setLocation({
        latitude: collPoint.address.location.latitude,
        longitude: collPoint.address.location.longitude
      });
      this._setLocationDisable(true);
    }
  }

  private loadAllCollectionPoints() {
    let obs = this.dumpsterService.getCollectionPoints();
    obs.subscribe((result) => {
      this.allCollectionPoints = result.collectionPoints;
      this.collectionPoints = result.collectionPoints;
    });

    return obs;
  }

  public goToGroupDetail(group: Group) {
    this.router.navigate(['collectionpoints', 'groups', group.id]);
  }

  //
  public openEventDetailDialog(event: DumpsterEventsOutputItem) {
    let input = {
      service: event.service,
      supplier: event.supplier,
      entityId: event.entityId,
      id: event.id,
    } as DumpsterEventDetailInput;

    this.dumpsterService.getDumpsterEventDetail(input).subscribe((res) => {
      this.dialogService.openDialog(
        'Evento',
        new DumpsterEventDetailDialogInput(
          res,
          this.entities.payloadTypes,
          this.entities.wasteTypes
        ),
        DumpsterEventDetailDialogComponent,
        this.windowVisualizer,
        () => {},
        null,
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 350,
          mediumWidth: 800,
        } as BeanDialogDimension
      );
    });
  }

  public onMarkerSet = (data: google.maps.LatLng) => {
    if (!data) return;
    this._setLocation({
      latitude: data.lat(),
      longitude: data.lng()
    });
  };

  public viewTicket(dumpsterId: string, ticketId: string) {
    this.router.navigate(['tickets', 'list', dumpsterId, ticketId]);
  }

  public newTicket() {
    this.router.navigate(['tickets', 'list', 'new'],{ queryParams: { deviceExternalId: this.dumpster.deviceExternalId } });
  }

  public setIconMap = (data: Dumpster) => this.iconService.getDetailIcon(data);
}
