import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faList, faPlay } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { ErrorEvent as UploadErrorEvent, FileRestrictions, SuccessEvent as UploadSuccessEvent } from '@progress/kendo-angular-upload';
import { ToastService } from '@veritas-shared/services/toast.service';
import { ImportBehavior } from '@veritas-shared/models/import-behavior';
import { ImportResultOutput } from '@veritas-shared/models/import-result-output';
import { DumpsterListDeactivateDumpstersInput } from '../../models/dumpster-list-deactivate-dumpsters-input';
import { EditDumpsterUpdateLabelsInput } from '../../models/edit-dumpster-update-labels-input';
import { DumpsterService } from '../../services/dumpster.service';
import { DumpsterGridComponent } from '@veritas-shared/components/dumpster-grid/dumpster-grid.component';
import { DumpsterMapComponent } from '@veritas-shared/components/dumpster-map/dumpster-map.component';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { Label } from '@veritas-shared/models/label';
import { GroupListOutput, GroupListOutputGroup } from '../../../group/models/group-list-output';
import { GroupService } from '../../../group/services/group.service';
import { DumpsterListAddToGroupsInput } from '../../models/dumpster-list-add-to-group-input';
import { NewLabelInput } from '../../models/new-label-input';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { SessionService } from '@veritas-shared/services/session.service';
import { Action } from '@veritas-shared/models/action';
import { CommandListOutputCommand } from '../../../commands/models/command-list-output';
import { CommandService } from '../../../commands/services/command.service';
import { RunCommandInput } from '../../../commands/models/run-command-input';
import { BeanKendoDialogService, WindowContainerDimension, WindowVisualizerDirective } from 'bean-kendo-dialog';
import { GroupSelectDialogComponent, GroupSelectDialogInput } from '../../components/group-select-dialog/group-select-dialog.component';
import { map } from 'rxjs/operators';
import { BeanDialogDimension } from 'bean-kendo-dialog/lib/classes/bean-dialog-dimension.class';
import { Observable, of, Subscription } from 'rxjs';
import { RunCommandDialogComponent, RunCommandDialogInput } from '../../components/run-command-dialog/run-command-dialog.component';
import { DumpsterStatus } from '@veritas-shared/enums/dumpster-status.enum';
import { SecurityService } from '@veritas-shared/services/security.service';
import { ISelectableCheckboxSettings } from '../../../shared/models/selectablecheckboxsettings.interface';
import { NavigationService } from '@veritas-shared/services/navigation.service';

@Component({
  selector: 'app-dumpsters',
  templateUrl: './dumpsters.component.html',
  styleUrls: ['./dumpsters.component.scss']
})
export class DumpstersComponent implements OnDestroy {

  @ViewChild(DumpsterGridComponent, { read: DumpsterGridComponent }) dumpsterGrid: DumpsterGridComponent;
  @ViewChild(DumpsterMapComponent, { read: DumpsterMapComponent }) dumpsterMap: DumpsterMapComponent;
  @ViewChild(WindowVisualizerDirective, { static: true }) public windowVisualizer: WindowVisualizerDirective;

  public filteredDumpsters: Dumpster[];
  private _tempFilteredDumpsters: Dumpster[]; // used for temporary store devices when show only selected is checked
  public labels: Label[];

  public dumpsters: Dumpster[];
  public selectedDumpsters: Dumpster[] = [];

  public actions: Action[];
  public toolbarActions: Action[] = [];
  public defaultGridFilter: CompositeFilterDescriptor;

  public isLabelsOpen: boolean;
  public isLabelsDialogOpen: boolean = false;
  public isShowOnlySelected: boolean = false;

  // set groups
  private allGroups: GroupListOutputGroup[] = [];

  // upload section
  public isImportDialogOpen: boolean = false;
  public isMergeEnabled: boolean = false;
  public uploadSaveUrl: string = '';
  public importResult: ImportResultOutput;
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv']
  };

  // command
  private commands: CommandListOutputCommand[] = [];

  private subscription: Subscription;
  private externalFilter: DumpsterStatus;
  public selectableCheckboxSettings: ISelectableCheckboxSettings;

  constructor(
    private dumpsterService: DumpsterService,
    private groupService: GroupService,
    private commandService: CommandService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private library: FaIconLibrary,
    private translate: TranslateService,
    private router: Router,
    public constantHelper: ConstantHelper,
    private sessionService: SessionService,
    private dialogService: BeanKendoDialogService,
    private securityService: SecurityService,
    private navigationService: NavigationService
  ) {

    this.sessionService.setPageTitle(this.constantHelper.LBL_MENU_DUMPSTERS_REGISTRY);
    this.isLabelsOpen = !this.sessionService.getDumpsterLabelPaneHidden();

    this.library.addIcons(faAngleDoubleLeft, faAngleDoubleRight, faList, faPlay);

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.deactivateBulk = this.deactivateBulk.bind(this);
    this.runCommand = this.runCommand.bind(this);
    this.setGroups = this.setGroups.bind(this);
    this.openLabelsDialog = this.openLabelsDialog.bind(this);
    //this.openImportDialog = this.openImportDialog.bind(this);
    //this.closeImportDialog = this.closeImportDialog.bind(this);

    this.selectableCheckboxSettings = {
      context: this,
      isSelectable: (dataItem: Dumpster, ctx: DumpstersComponent, currentSelectedIds: number[]) => {

        // GarbageType deve essere uguale
        if (currentSelectedIds && currentSelectedIds.length > 0) {
          let first = ctx.dumpsters.find(el => el.id == currentSelectedIds[0]);
          return dataItem.garbageType === first.garbageType;
        }

        return true;
      }
    }

    this.securityService.userHasPermission(this.constantHelper.LBL_PERMISSION_DUMPSTER_WRITE).subscribe(result => {
      if (result) {
        this.toolbarActions = [
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DEACTIVATE, 'power-off', this.deactivateBulk, true, true),
          new Action('setLabels', this.constantHelper.LBL_ACTION_DUMPSTER_SET_LABELS, 'tags', this.openLabelsDialog, true),
          new Action('setGroups', this.constantHelper.LBL_ACTION_DUMPSTER_SET_AUTH_GROUPS, 'users', this.setGroups, true),
          new Action('runCommand', this.constantHelper.LBL_ACTION_DUMPSTER_RUN_COMMAND, 'play', this.runCommand, true, false,
            (dumpster: Dumpster) => {
              return dumpster && (dumpster.regionCode == null || dumpster.regionCode == "") && dumpster.groupCount == 0;
            }
          ),
        ];

        this.actions = [
          new Action('edit', this.constantHelper.LBL_ACTION_COMMON_EDIT, 'pencil-alt', this.edit),
          new Action('delete', this.constantHelper.LBL_ACTION_COMMON_DEACTIVATE, 'trash-alt', this.deactivateBulk),
          // new Action('setLabels', this.constantHelper.LBL_ACTION_DUMPSTER_SET_LABELS, 'tags', this.openLabelsDialog, true),
          // new Action('setGroups', this.constantHelper.LBL_ACTION_DUMPSTER_SET_AUTH_GROUPS, 'users', this.setGroups, true),
          //new Action('runCommand', this.constantHelper.LBL_ACTION_DUMPSTER_RUN_COMMAND, 'play', this.runCommand, true,
          //  (dumpster: Dumpster) => {
          //    return dumpster && (dumpster.regionCode == null || dumpster.regionCode == "") && dumpster.groupCount == 0;
          //  })
        ];
      }
    });

    this.uploadSaveUrl = this.dumpsterService.getUploadUrl(ImportBehavior.Insert);
    this.importResult = new ImportResultOutput(0, 0, []);

    //
    this.subscription = this.route.queryParams.subscribe(res => {

      // Get and set filter
      if (res) {
        this.externalFilter = res['status'];
      }

      // Load data
      this.loadData(false, this.externalFilter);
    });
  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  private loadData(preserveFilter?: boolean, filterStatus?: DumpsterStatus) {
    this.dumpsterService.getDumpsters().subscribe(result => {
      this.dumpsters = result.dumpsters;
      this.labels = result.labels;

      // Preserve Filter
      if (preserveFilter) {
        let newFilteredDumpster = [];
        this.filteredDumpsters.forEach(fd => {
          newFilteredDumpster.push(result.dumpsters.find(d => d.id == fd.id));
        });
        this.filteredDumpsters = newFilteredDumpster;
        let newSelectedDumpster = [];
        this.selectedDumpsters.forEach(sd => {
          newSelectedDumpster.push(result.dumpsters.find(d => d.id == sd.id));
        });
        this.selectedDumpsters = newSelectedDumpster;
      }
      else {
        this.filteredDumpsters = this.dumpsters;
        this.selectedDumpsters = [];
      }

      // Filter By Status
      if (filterStatus) {
        switch (filterStatus) {
          case DumpsterStatus.Error:
            this.filteredDumpsters = this.dumpsters.filter(el => el.errorCount > 0);
            break;

          case DumpsterStatus.Warning:
            this.filteredDumpsters = this.dumpsters.filter(el => el.errorCount == 0 && el.warningCount > 0);
            break;
        }
      }
    });
  }

  public toggleLabels() {
    this.isLabelsOpen = !this.isLabelsOpen;
    this.sessionService.setDumpsterLabelPaneHidden(this.isLabelsOpen == false);
  }

  public onTabChange(event: SelectEvent) {
    if (event.index == 0) {
      this.dumpsterGrid.refreshSelection();
    }
    if (event.index == 1) {
      window.setTimeout(() => this.dumpsterMap.centerAndZoomOnMarkers(), 200);
    }
  }

  public onSearchChanged() {
    this.selectedDumpsters = [];
    this.isShowOnlySelected = false;
    this.dumpsterGrid.goToPage(0);
  }

  public onLabelSelected(label: string) {
    if (label) {
      this.filteredDumpsters = this.dumpsters.filter(d => {
        return d.labels.findIndex(l => l.name == label) > -1;
      });
    }
    else {
      this.filteredDumpsters = this.dumpsters;
    }
  }

  public toggleShowOnlySelected() {
    if (this.isShowOnlySelected) {
      this._tempFilteredDumpsters = this.filteredDumpsters;
      this.filteredDumpsters = this.selectedDumpsters;
      this.dumpsterGrid.goToPage(0);
    }
    else {
      this.filteredDumpsters = this._tempFilteredDumpsters;
      this._tempFilteredDumpsters = [];
    }
  }

  public add() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  public edit(dumpster: Dumpster) {
    this.router.navigate([dumpster.id], { queryParams: { edit: true }, relativeTo: this.route });
  }

  public setGroups() {
    let observable: Observable<any>;

    if (this.allGroups.length == 0) {
      observable = this.loadAllGroups();
    }
    else {
      observable = of(this.allGroups);
    }

    observable.subscribe(res => {
      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTER_DIALOG_SETGROUPS_TITLE),
        new GroupSelectDialogInput(this.allGroups),
        GroupSelectDialogComponent,
        this.windowVisualizer,
        () => { },
        (res: GroupListOutputGroup[]) => {

          // Add selected devices
          const addedGroupsIds = res.map(d => d.id);
          const input = new DumpsterListAddToGroupsInput(this.selectedDumpsters.map(d => d.id), addedGroupsIds);

          return this.dumpsterService.addToGroups(input).pipe(map((result) => {
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_DEVICES_DIALOG_ADDTOGROUP_CONFIRM_MESSAGE)
            );
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });
  }

  private loadAllGroups(): Observable<GroupListOutput> {

    let obs = this.groupService.getGroups();
    obs.subscribe(result => {
      this.allGroups = result.groups.filter(el => !el.isGlobal);
    });

    return obs;
  }

  public deactivateBulk() {
    if (this.selectedDumpsters.length > 0) {
      const toDeactivate = new DumpsterListDeactivateDumpstersInput(this.selectedDumpsters.map(d => d.id));
      this.dumpsterService.deactivateDumpsters(toDeactivate).subscribe(result => {
        this.selectedDumpsters = [];
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_AUTHGROUP_DETAIL_DELETEDUMPSTERS_CONFIRM_MESSAGE, { '0': this.selectedDumpsters.length })
        );
        // Reload devices
        this.dumpsterService.getDumpsters().subscribe(result => {
          this.dumpsters = result.dumpsters;
          this.labels = result.labels;
          this.filteredDumpsters = result.dumpsters;
        });
      });
    }
  }

  public openLabelsDialog() {
    this.isLabelsDialogOpen = true;

    //this.dialogService.openDialog(
    //  this.translate.instant(this.constantHelper.LBL_COMPONENT_DEVICE_DIALOG_SELECTLABELS),
    //  new LabelSelectDialogInput(this.selectedDumpsters),
    //  LabelSelectDialogComponent,
    //  this.windowVisualizer,
    //  () => { },
    //  (res: EditDumpsterUpdateLabelsInput[] | NewLabelInput) => {

    //    if (res instanceof NewLabelInput) {
    //    }


    //    return of(true);
    //  },
    //  true,
    //  WindowContainerDimension.Small,
    //  {
    //    smallHeight: 450,
    //    smallWidth: 500,
    //  } as BeanDialogDimension
    //);
  }

  public closeLabelsDialog(result: string, input: EditDumpsterUpdateLabelsInput[] | NewLabelInput) {

    if (result == 'edit') {
      this.dumpsterService.updateLabels(input as EditDumpsterUpdateLabelsInput[]).subscribe(result => {
        this.toastService.showSuccess(
          this.translate.instant(this.constantHelper.LBL_PAGE_DEVICES_DIALOG_LABELS_SUCCESS_MESSAGE)
        );
        // Refresh grid
        this.loadData(true);
        this.isLabelsDialogOpen = false;
      });
    }
    else if (result == 'new') {
      this.dumpsterService.createAndAssignLabel(input as NewLabelInput).subscribe(result => {
        // Refresh grid
        this.loadData(true);
        this.isLabelsDialogOpen = false;
      });
    }
    else {
      this.isLabelsDialogOpen = false;
    }
  }

  /*
  * Upload
  **/
  public openImportDialog() {
    // reset result
    this.importResult = new ImportResultOutput(0, 0, []);
    this.isMergeEnabled = false;

    this.isImportDialogOpen = true;
  }

  public closeImportDialog() {
    this.isImportDialogOpen = false;
  }

  public toggleMergeEnabled() {
    this.uploadSaveUrl = this.dumpsterService.getUploadUrl(this.isMergeEnabled ? ImportBehavior.Merge : ImportBehavior.Insert);
  }

  public successEventHandler(e: UploadSuccessEvent) {
    this.importResult = <ImportResultOutput>e.response.body;
    if (this.importResult.validRows > 0) {
      this.loadData();
    }
  }

  public errorEventHandler(e: UploadErrorEvent) {
    this.toastService.showError(e.response.statusText);
  }

  /*
   * Command
   */
  // é ApplyOnSelected quindi lui lavora con le righe selezionate, se però non ne seleziono nessuna lui non lo sa
  public runCommand() {
    let observable: Observable<any>;

    if (this.commands.length == 0) {
      observable = this.loadAllCommands();
    }
    else {
      observable = of(this.commands);
    }

    observable.subscribe(res => {

      let wasteType = this.selectedDumpsters[0].garbageType;

      this.dialogService.openDialog(
        this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTERS_DIALOG_RUN_COMMAND_TITLE),
        new RunCommandDialogInput(this.commands, wasteType),
        RunCommandDialogComponent,
        this.windowVisualizer,
        () => { },
        (input: RunCommandInput) => {

          input.dumpsterIds = this.selectedDumpsters.map(d => d.id);

          // Execute
          return this.commandService.runCommand(input).pipe(map((res) => {
            this.toastService.showSuccess(
              this.translate.instant(this.constantHelper.LBL_PAGE_DUMPSTERS_DIALOG_RUN_COMMAND_CONFIRMATION_MESSAGE)
            );
          }));
        },
        true,
        WindowContainerDimension.Medium,
        {
          mediumHeight: 600,
          mediumWidth: 1000,
        } as BeanDialogDimension
      );
    });

  }

  private loadAllCommands() {
    let obs = this.commandService.getCommands();
    obs.subscribe(result => {
      this.commands = result.commands;
    });

    return obs;
  }
}
