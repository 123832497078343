import { Injectable, Inject } from '@angular/core';
import { ApiService } from '@veritas-shared/services/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { NewDumpsterOutput } from '../models/new-dumpster-output';
import { EditDumpsterOutput } from '../models/edit-dumpster-output';
import { EditDumpsterInput } from '../models/edit-dumpster-input';
import { EditDumpsterUpdateLabelsInput } from '../models/edit-dumpster-update-labels-input';
import { ImportBehavior } from '@veritas-shared/models/import-behavior';
import { DumpsterListDeactivateDumpstersInput } from '../models/dumpster-list-deactivate-dumpsters-input';
import { WaitService } from '@veritas-shared/services/wait.service';
import { DumpsterListAssociateCollectionPointInput } from '../models/dumpster-list-associate-collectionpoint-input';
import { DumpsterListOutput } from '../models/dumpster-list-output';
import { DumpsterListAddToGroupsInput } from '../models/dumpster-list-add-to-group-input';
import { NewLabelInput } from '../models/new-label-input';
import { DumpsterListDeassociateCollectionPointInput } from '../models/dumpster-list-deassociate-collectionpoint-input';
import { DumpsterListDeleteDumpstersInput } from '../models/dumpster-list-delete-dumpsters-input';
import { DumpsterListActivateDumpstersInput } from '../models/dumpster-list-activate-dumpsters-input';
import { ToastService } from '@veritas-shared/services/toast.service';
import { CommonHelper } from '@veritas-shared/helpers/common.helper';
import { ConstantHelper } from '@veritas-shared/helpers/constant.helper';
import { DumpsterInitRegisterInput, DumpsterRegisterInput } from '../models/dumpster-register-input';
import { DumpsterEventsInput, DumpsterEventsOutput } from '../models/dumpster-events';
import { DumpsterEventDetailInput, DumpsterEventDetailOutput } from '../models/dumpster-event-detail';
import { ToastService as BeanToastService } from "bean-notification";
import { DumpsterRegisterOutput } from '../models/dumpster-register-output';
import { FileContent } from '../../shared/models/file-content';
import { DumpsterConfigurationDialogInput } from '@veritas-dumpsters/components/dumpster-configuration-dialog/dumpster-configuration-dialog.component';
import { CollectionPointListOutput } from '@veritas-shared/models/collectionpoint-list-output';
import { CustomAddressInput } from '@veritas-dumpsters/models/custom-address-input';
import { dumpsterById, getRelationChildUrl, removeChildUrl, setChildUrl } from '@veritas-dumpsters/constants/api-dumpster.const';
import { IDumpsterRelationReq } from '@veritas-dumpsters/models/dumpster-relation.model';
import { Dumpster } from '@veritas-shared/models/dumpster';
import { IPaginationItem } from '@veritas-shared/models/pagination-item.mode.';
import { IRelationChild } from '@veritas-dumpsters/models/relation-child.model';

@Injectable({
  providedIn: 'root'
})
export class DumpsterService extends ApiService {

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    toastService: ToastService,
    waitService: WaitService,
    private constantHelper: ConstantHelper,
    commonHelper: CommonHelper,
    newToastService: BeanToastService
  ) {
    super(http, baseUrl, toastService, waitService, commonHelper, newToastService);
  }

  public getDeassociatedDumpsters(): Observable<DumpsterListOutput> {
    return this.getBase<DumpsterListOutput>(this.constantHelper.DUMPSTERS_GET_DEASSOCIATED);
  }

  public getDumpsters(): Observable<DumpsterListOutput> {
    return this.getBase<DumpsterListOutput>(this.constantHelper.DUMPSTERS_BASE);
  }

  public getDeactivatedDumpsters(): Observable<DumpsterListOutput> {
    return this.getBase<DumpsterListOutput>(this.constantHelper.DUMPSTERS_GET_DEACTIVATED);
  }

  public getUnregisteredDumpsters(): Observable<DumpsterListOutput> {
    return this.getBase<DumpsterListOutput>(this.constantHelper.DUMPSTERS_GET_UNREGISTERED);
  }

  public getNewDumpster(): Observable<NewDumpsterOutput> {
    return this.getBase<NewDumpsterOutput>(this.constantHelper.DUMPSTERS_GET_NEW);
  }

  public getEditDumpster(id: number): Observable<EditDumpsterOutput> {
    const url = this.commonHelper.replaceRouteParams(this.constantHelper.DUMPSTERS_GET_EDIT, { id: id });
    return this.getBase<EditDumpsterOutput>(url);
  }

  public getByDumpsterId(dumpsterId: string): Observable<EditDumpsterOutput> {
    const url = this.commonHelper.replaceRouteParams(this.constantHelper.DUMPSTERS_GET_BY_DUMPSTER_ID, { dumpsterId: dumpsterId });
    return this.getBase<EditDumpsterOutput>(url);
  }

  public getByDumpsterIdV2 = (dumpsterId: string): Observable<Dumpster> => this.getBase<any>(dumpsterById(dumpsterId));

  public getDumpsterEvents(input: DumpsterEventsInput): Observable<DumpsterEventsOutput> {
    return this.postBase<DumpsterEventsInput, DumpsterEventsOutput>(this.constantHelper.DUMPSTERS_GET_EVENTS, input);
  }

  public getDumpsterEventDetail(input: DumpsterEventDetailInput): Observable<DumpsterEventDetailOutput> {
    return this.postBase<DumpsterEventDetailInput, DumpsterEventDetailOutput>(this.constantHelper.DUMPSTERS_GET_EVENT_DETAIL, input);
  }

  public downloadDumpsterEventDetail(input: DumpsterEventDetailInput): Observable<FileContent> {
    return this.downloadBase<FileContent>(this.constantHelper.DUMPSTERS_DOWNLOAD_EVENT_DETAIL, input);
  }

  //public addDumpster(device: NewDumpsterInput): Observable<void> {
  //  return this.postBase<NewDumpsterInput, void>(this.constantHelper.DUMPSTERS_BASE, device);
  //}

  public updateDumpster(device: EditDumpsterInput): Observable<void> {
    return this.putBase<EditDumpsterInput, void>(this.constantHelper.DUMPSTERS_BASE, device);
  }

  public activateDumpsters(dumpsters: DumpsterListActivateDumpstersInput): Observable<void> {
    return this.putBase<DumpsterListActivateDumpstersInput, void>(this.constantHelper.DUMPSTERS_REACTIVATE, dumpsters);
  }

  public deactivateDumpsters(dumpsters: DumpsterListDeactivateDumpstersInput): Observable<void> {
    return this.putBase<DumpsterListDeactivateDumpstersInput, void>(this.constantHelper.DUMPSTERS_DEACTIVATE, dumpsters);
  }

  public deleteDumpsters(dumpsters: DumpsterListDeleteDumpstersInput): Observable<void> {
    return this.putBase<DumpsterListDeleteDumpstersInput, void>(this.constantHelper.DUMPSTERS_DELETE_MULTI, dumpsters);
  }

  public deleteDumpster(id: number): Observable<void> {
    const url = this.commonHelper.replaceRouteParams(this.constantHelper.DUMPSTERS_DELETE, { id: id });
    return this.deleteBase(url);
  }

  public updateLabels(labels: EditDumpsterUpdateLabelsInput[]): Observable<void> {
    return this.putBase<EditDumpsterUpdateLabelsInput[], void>(this.constantHelper.DUMPSTERS_LABEL_UPDATE, labels);
  }

  public createAndAssignLabel(input: NewLabelInput): Observable<void> {
    return this.putBase<NewLabelInput, void>(this.constantHelper.DUMPSTERS_LABEL_NEW, input);
  }

  public getCollectionPoints(): Observable<CollectionPointListOutput> {
    return this.getBase<CollectionPointListOutput>(this.constantHelper.DUMPSTERS_GET_COLLECTION_POINT);
  }

  public associateCollectionPoint(dumpsters: DumpsterListAssociateCollectionPointInput): Observable<void> {
    return this.putBase<DumpsterListAssociateCollectionPointInput, void>(this.constantHelper.DUMPSTERS_ASSOCIATE_COLLECTION_POINT, dumpsters);
  }

  public deassociateCollectionPoint(dumpsters: DumpsterListDeassociateCollectionPointInput): Observable<void> {
    return this.putBase<DumpsterListDeassociateCollectionPointInput, void>(this.constantHelper.DUMPSTERS_DEASSOCIATE_COLLECTION_POINT, dumpsters);
  }

  public addToGroups(input: DumpsterListAddToGroupsInput): Observable<void> {
    return this.putBase<DumpsterListAddToGroupsInput, void>(this.constantHelper.DUMPSTERS_ADD_TO_GROUP, input);
  }

  //public upload(importBehavior: ImportBehavior, files: FileInfo[]): Observable<ValidationOutputModel> {
  //  // array or single file
  //  const formData: FormData = new FormData();
  //  files.forEach(file => {
  //    formData.append('files', file.rawFile, file.name);
  //  });
  //  return this.postBase<FormData, ValidationOutputModel>('api/dumpster/upload?importBehavior=' + importBehavior, formData);
  //}

  public getUploadUrl(importBehavior: ImportBehavior): string {
    return `${this.constantHelper.DUMPSTERS_UPLOAD}?importBehavior=${importBehavior}`;
  }

  public getRegisterData(data: DumpsterInitRegisterInput): Observable<DumpsterRegisterOutput> {
    return this.postBase<DumpsterInitRegisterInput, DumpsterRegisterOutput>(this.constantHelper.DUMPSTERS_GET_REGISTER_DATA, data);
  }

  public registerDumpster(device: DumpsterRegisterInput): Observable<void> {
    return this.postBase<DumpsterRegisterInput, void>(this.constantHelper.DUMPSTERS_REGISTER, device);
  }

  public dumpsterConfiguration(input: DumpsterConfigurationDialogInput): Observable<void> {
    return this.postBase<DumpsterConfigurationDialogInput, void>(this.constantHelper.LBL_ACTION_DUMPSTER_SET_CONFIGURATION, input);
  }

  public setCustomAddress(input: CustomAddressInput): Observable<void> {
    return this.postBase<CustomAddressInput, void>(this.constantHelper.DUMPSTERS_SET_CUSTOM_ADDRESS, input);
  }

  public resetCustomAddress(id: number): Observable<void> {
    const url = this.commonHelper.replaceRouteParams(this.constantHelper.DUMPSTERS_RESET_CUSTOM_ADDRESS, { id: id });
    return this.getBase<void>(url);
  }

  public setChildDumpster = (
    id: string, child: IDumpsterRelationReq
  ): Observable<void> => this.postBase<IDumpsterRelationReq, any>(setChildUrl(id), child);

  public removeChildDumpster = (
    id: string, child: IDumpsterRelationReq
  ): Observable<void> => this.postBase<IDumpsterRelationReq, any>(removeChildUrl(id), child);

  public getRelationChild = (
    request: IPaginationItem
  ): Observable<IRelationChild[]> => this.postBase<IPaginationItem, IRelationChild[]>(getRelationChildUrl(), request)
}
